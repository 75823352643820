import React, {useContext, useEffect, useRef, useState} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import CustomLink from "../../components/menu";
import {Link} from "gatsby";
import LangContext from "../../context/LangContext"

const NosotrosPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    const {lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }
    return (
        <Layout location={props.location}>
            <SEO title="Politicas de privacidad"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Mapa del sitio</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                            MENÚ
                                        </h6>
                                        <ul className={'mapa-sitio'}>
                                            <li>
                                                <Link to={'/es/nosotros'}>Nosotros</Link>
                                            </li>
                                            <li>
                                                <Link to={'/es/servicios'}>Servicios</Link>
                                            </li>
                                            <li>
                                                <Link to={'/es/sectores'}>Sectores</Link>
                                            </li>
                                            <li>
                                                <Link to={'/es/contacto'}>Contacto</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                            LINKS COMPLEMENTARIOS
                                        </h6>
                                        <ul className={'mapa-sitio'}>
                                            <li>
                                                <Link to="/es/politica-privacidad">Política de Privacidad</Link>
                                            </li>
                                            <li>
                                                <Link to="/es/terminos-condiciones">Términos de Uso</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
